import ApiService from "../../core/services/api.service";

const apiService = ApiService;

export default class BannerService {
  #api = null;

  constructor() {
    this.#api = `admin/banner`;
  }

  all() {
    let url = `${this.#api}`;
    return apiService.get(url);
  }

  store(data) {
    let url = `${this.#api}`;
    return apiService.post(url, data);
  }

  show(id) {
    let url = `${this.#api}/${id}`;
    return apiService.get(url);
  }

  update(id, data) {
    let url = `${this.#api}/${id}`;
    return apiService.put(url, data);
  }

  delete(id) {
    let url = `${this.#api}/${id}`;
    return apiService.delete(url);
  }

  getAllProductAndCategory(type, data) {
    let url = `${this.#api}`;
    if (type != null) url = `${url}?type=${type}`;
    let param = {
      params: data
    };
    return apiService.query(url, param);
  }
}
