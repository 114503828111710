<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Manage Banner'">
          <template v-slot:preview>
            <v-card-title>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12">
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      @click="createOrUpdate"
                    >
                      Add Banner
                    </v-btn>
                  </v-toolbar>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text v-if="images.length > 0">
              <v-row>
                <v-col
                  v-for="(image, index) of images"
                  :key="index"
                  cols="12"
                  md="2"
                >
                  <v-card style="position: relative" max-width="250">
                    <v-img
                      :lazy-src="image.thumb"
                      :src="image.thumb"
                      @click="openGallery(index)"
                    ></v-img>
                    <v-btn
                      class="deleteBtn"
                      @click="deleteConfirm(image.id)"
                      absolute
                      rounded
                      right
                      top
                    >
                      <v-icon small color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-card>
                  <a :href="image.link">
                    <div
                      class="mt-2 font-size-lg badge badge-primary text-center"
                    >
                      {{ image.type_text }}
                    </div>
                  </a>
                </v-col>
              </v-row>
              <LightBox
                ref="lightbox"
                :media="images"
                :showLightBox="false"
              ></LightBox>
            </v-card-text>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import BannerService from "../../../service/banner/BannerService";
import LightBox from "vue-image-lightbox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const bannerService = new BannerService();
export default {
  name: "ManageBanner",
  components: {
    LightBox,
    KTCodePreview
  },
  data() {
    return {
      images: [],
      showModal: false
    };
  },
  mounted() {
    this.getAllBanner();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Banner" },
      { title: "Manage Banner" }
    ]);
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    openGallery(index) {
      this.$refs["lightbox"].showImage(index);
    },
    createOrUpdate() {
      this.$router.push({
        name: "banner-create"
      });
    },
    getAllBanner() {
      bannerService.all().then(response => {
        this.images = response.data.data;
      });
    },
    deleteConfirm(id) {
      this.$confirm({
        message: `Are you Sure ??`,
        button: {
          yes: "Yes",
          no: "No"
        },
        callback: confirm => {
          if (confirm) {
            bannerService.delete(id).then(response => {
              if (response.data.status == "OK") {
                this.$snotify.success("Deleted Successfully !!!");
                this.getAllBanner();
              }
            });
          }
        }
      });
    }
  }
};
</script>
<style scoped>
.v-btn--absolute.v-btn--top,
.v-btn--fixed.v-btn--top {
  top: 0 !important;
}
.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: 1px !important;
}
.deleteBtn {
  min-width: 36px !important;
  padding: 0 !important;
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: #f5f5f5;
}
</style>
